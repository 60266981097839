import ReactPlayer from "react-player";

import { ArrowIcon, ButtonPrimaryDark } from "@components/buttons";
import styled, { css } from "styled-components";

import Grid from "@anwb/poncho/components/grid";
import { pxToRem } from "@anwb/poncho/design-tokens/style-utilities";
import { DefaultThemeProps } from "@anwb/poncho/design-tokens/theme";

import { customVariables } from "@topgun/shared-front-end/src/theme/custom-variables";
import { AuthorImageProps } from "@topgun/shared-front-end/src/types/frontend";
import { BodyLargeEmphasis, HeadingNarrowBoldCaps } from "@topgun/shared-front-end/src/typography";
import { getBackgroundPositionCssForImageWithFocalPoint } from "@topgun/shared-front-end/src/utils/focalPoint";
import { scaleBynderImage } from "@topgun/shared-front-end/src/utils/image";

export const TextContentContainer = styled.div`
  gap: 1.5em;
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const TitleTrainer = styled(HeadingNarrowBoldCaps)`
  padding-left: 0.8rem;
`;

const videoGridItemStyles = ({ theme }: DefaultThemeProps) => css`
  @media (max-width: ${pxToRem(theme.viewportBreakpoint.md)}) {
    order: -1;
    margin-top: 1rem;
  }
`;

export const VideoGridItem = styled(Grid.Item)`
  ${videoGridItemStyles}
`;

const IMAGE_HEADER_TOP = "6rem";

type ImageProps = {
  image?: AuthorImageProps;
};

export const imageContentWrapperStyles = ({ theme }: DefaultThemeProps) => css`
  position: relative;
  max-width: 80rem;
  margin: 0 auto;
  padding: 3rem 2rem 0;

  @media (max-width: ${pxToRem(theme.viewportBreakpoint.sm)}) {
    padding: 1rem 1rem 0;
  }
`;

export const ImageContentWrapper = styled.div`
  ${imageContentWrapperStyles}
`;

const imageBackgroundWrapperstyles = ({ theme, image }: DefaultThemeProps & ImageProps) => css`
  position: absolute;
  padding-top: 75.1%; /* = (img-height / img-width * container-width) = (616 / 820 * 100)*/
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: linear-gradient(
      180deg,
      rgba(0, 21, 47, 0.3) 0%,
      ${customVariables.colors.primaryBackground} 75%
    ),
    url(${image && scaleBynderImage(image.url)});
  ${getBackgroundPositionCssForImageWithFocalPoint(image)};

  @media (max-width: ${pxToRem(theme.viewportBreakpoint.sm)}) {
    top: ${pxToRem(IMAGE_HEADER_TOP)};
  }
`;

export const ImageBackgroundWrapper = styled.div<DefaultThemeProps & ImageProps>`
  ${imageBackgroundWrapperstyles}
`;

export const ButtonWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: end;
`;

export const StartButton = styled(ButtonPrimaryDark).attrs({
  icon: ArrowIcon.RIGHT,
})``;

const reactPlayerElementStyling = () => css`
  max-width: 100%;
  max-height: 100%;
  video {
    border-radius: 1.5rem;
  }
`;

export const ReactPlayerElement = styled(ReactPlayer)`
  ${reactPlayerElementStyling};
`;

export const TotalAmount = styled(BodyLargeEmphasis)`
  padding-left: 1rem;
`;
