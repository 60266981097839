import { FunctionComponent, ReactNode, useState } from "react";
import { createPortal } from "react-dom";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { Dialog } from "@components/Dialog";
import { useHeaderState, useHeaderUpdater } from "@components/HeaderContextProvider";
import HeaderMenu from "@components/HeaderMenu";
import Onboarding from "@components/Onboarding";
import { NavigationButtonIcon } from "@components/buttons";
import { DialogTextFor } from "@domain/types";
import type { To } from "@remix-run/router";
import { AUTH_CLIENT_ID } from "@utils/constants";

import BodyL from "@topgun/shared-front-end/src/typography/BodyL";

import logo from "./ANWB_logo.svg";
import {
  AnwbLogoImage,
  BreadCrumb,
  BreadCrumbWrapper,
  HeaderCenterElement,
  HeaderFlexContainer,
  HeaderLeftElement,
  HeaderRightElement,
  HeaderWrapper,
  NavigateBackWrapper,
} from "./styles";

interface Props {
  hideMenu?: boolean;
  rightElementContent?: ReactNode;
  className?: string;
}

const Header: FunctionComponent<Props> = ({ hideMenu, rightElementContent, className }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [leavePageUrl, setLeavePageUrl] = useState<To | undefined>(undefined);
  const {
    showHeaderLogo,
    showHeaderMenu,
    showOnboarding,
    navigateBack,
    breadCrumbs,
    confirmPageLeave,
    confirmPageLeaveOptions,
  } = useHeaderState();

  const { setLeaveConfirmationOptions } = useHeaderUpdater();

  const handleNavigate = (confirm: boolean, url: To, dialogTextFor?: DialogTextFor) => {
    if (confirm) {
      if (dialogTextFor === DialogTextFor.RESTART) {
        setLeaveConfirmationOptions({
          title: t("modal.restartActivityTitle"),
          description: t("modal.restartActivityDescription"),
          confirmButton: t("modal.restartActivityConfirm"),
          cancelButton: t("modal.restartActivityCancel"),
        });
      } else if (dialogTextFor === DialogTextFor.DASHBOARD) {
        setLeaveConfirmationOptions({
          title: t("modal.exitToDashboardActivityTitle"),
          description: t("modal.exitToDashboardActivityDescription"),
          confirmButton: t("modal.exitToDashboardActivityConfirm"),
          cancelButton: t("modal.exitToDashboardActivityCancel"),
        });
      } else {
        setLeaveConfirmationOptions({
          title: t("modal.exitActivityTitle"),
          description: t("modal.exitActivityDescription"),
          confirmButton: t("modal.exitActivityConfirm"),
          cancelButton: t("modal.exitActivityCancel"),
        });
      }
      setLeavePageUrl(url);
    } else {
      navigate(url);
      setLeavePageUrl("");
    }
  };

  const showSidePanels = showHeaderLogo || navigateBack || breadCrumbs || rightElementContent;

  return (
    <HeaderWrapper data-testid="header" className={className}>
      <HeaderFlexContainer>
        {showSidePanels && (
          <HeaderLeftElement>
            {navigateBack && !hideMenu && (
              <NavigateBackWrapper>
                <NavigationButtonIcon
                  data-testid="header-back-button"
                  icon={navigateBack.icon!}
                  $variant="previous"
                  onClick={() =>
                    handleNavigate(confirmPageLeave, "/dashboard", DialogTextFor.DASHBOARD)
                  }
                />
              </NavigateBackWrapper>
            )}

            {breadCrumbs && breadCrumbs.length > 0 && !hideMenu && (
              <BreadCrumbWrapper>
                {breadCrumbs.map((crumb) => (
                  <BreadCrumb
                    key={crumb.label}
                    onClick={() => {
                      handleNavigate(confirmPageLeave, crumb.to, crumb.dialogTextFor);
                    }}
                    {...(crumb.disabled ? { disabled: true } : {})}
                  >
                    {crumb.label}
                  </BreadCrumb>
                ))}
              </BreadCrumbWrapper>
            )}

            <Dialog
              data-testid="header-page-leave-dialog"
              open={!!leavePageUrl}
              title={confirmPageLeaveOptions?.title || t("modal.leavePage.title")}
              buttonText={
                confirmPageLeaveOptions?.confirmButton || t("modal.leavePage.confirmButton")
              }
              cancelButtonText={
                confirmPageLeaveOptions?.cancelButton || t("modal.leavePage.cancelButton")
              }
              onOpenChange={(isOpen: boolean) => {
                if (!isOpen) {
                  setLeavePageUrl("");
                }
              }}
              buttonHandler={() => {
                if (leavePageUrl !== undefined) {
                  handleNavigate(false, leavePageUrl);
                }
              }}
            >
              <BodyL>
                {confirmPageLeaveOptions?.description || t("modal.leavePage.description")}
              </BodyL>
            </Dialog>
          </HeaderLeftElement>
        )}

        <HeaderCenterElement>{showHeaderMenu && !hideMenu && <HeaderMenu />}</HeaderCenterElement>
        {(showSidePanels || rightElementContent) && (
          <HeaderRightElement>
            {rightElementContent}
            {!rightElementContent && showHeaderLogo && (
              <a href="/">
                <AnwbLogoImage src={logo} alt="ANWB logo" />
              </a>
            )}
          </HeaderRightElement>
        )}
      </HeaderFlexContainer>
      {((showOnboarding && AUTH_CLIENT_ID === "dev") || AUTH_CLIENT_ID === "test") &&
        createPortal(<Onboarding />, document.body)}
    </HeaderWrapper>
  );
};

export default Header;
