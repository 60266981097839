import styled from "styled-components";

import { TypographyBaseProps } from "@anwb/poncho/components/typography";
import { pxToRem } from "@anwb/poncho/design-tokens/style-utilities";
import { DefaultThemeProps } from "@anwb/poncho/design-tokens/theme";

import { customVariables } from "@topgun/shared-front-end/src/theme/custom-variables";
import { BodyL, SubheadingM } from "@topgun/shared-front-end/src/typography";

export const Card = styled(BodyL)<TypographyBaseProps>``;

export const Segment = styled(SubheadingM)<TypographyBaseProps>`
  display: flex;
  align-items: flex-start;
  margin-bottom: 10px;
  text-align: left;
  gap: 1rem;

  @media (max-width: ${(props: DefaultThemeProps) => pxToRem(props.theme.viewportBreakpoint.sm)}) {
    font-size: 1.5rem;
  }
`;

export const SegmentInfo = styled.div`
  flex: 70;
`;

export const Title = styled.div``;

export const MaxErrors = styled(BodyL)<TypographyBaseProps>``;

export const Score = styled.div<{ success: boolean }>`
  flex: 30;
  color: ${({ success }) =>
    success ? customVariables.colors.uiSuccess : customVariables.colors.uiError};

  span {
    color: white;
  }
`;

export const Statement = styled.div<{ success: boolean }>`
  text-align: left;
  margin-top: 1.6rem;
  margin-bottom: 1rem;

  // TODO: is this selector still in use?
  .yes-no {
    color: ${({ success }) =>
      success ? customVariables.colors.uiSuccess : customVariables.colors.uiError};
    text-decoration: underline;
  }

  @media (min-width: ${(props: DefaultThemeProps) => pxToRem(props.theme.viewportBreakpoint.sm)}) {
    margin-top: 3rem;
    margin-bottom: 2rem;
  }
`;

export const Disclaimer = styled.div`
  padding-top: 1rem;
`;
