import { FunctionComponent, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { setUser } from "@sentry/react";
import { useAuth } from "oidc-react";

import CenteredSpinner from "@topgun/shared-front-end/src/components/CenteredSpinner";

export const AuthCallback: FunctionComponent = () => {
  const navigate = useNavigate();
  const { isLoading, userData } = useAuth();

  useEffect(() => {
    if (!isLoading && userData) {
      setUser({ id: userData.profile.sub });
      const { state: url } = userData || {};
      navigate(typeof url === "string" && url ? url : "/dashboard", {
        replace: true,
      });
    }
  }, [isLoading, userData, navigate]);

  return <CenteredSpinner size="large" />;
};
