import { FunctionComponent, MouseEvent } from "react";
import { useNavigate } from "react-router-dom";

import { PageResponse } from "@domain/types";
import useChapter from "@hooks/useChapter";

import Progress from "@anwb/poncho/components/progress";

import CenteredSpinner from "@topgun/shared-front-end/src/components/CenteredSpinner";
import { BodyLargeEmphasis } from "@topgun/shared-front-end/src/typography";

import {
  ChapterProgress,
  ChapterProgressIcon,
  ChapterProgressText,
  ChapterProgressWrapper,
  RemainedButton,
} from "./styles";

type ChapterProgressBarProps = {
  remainedWithLabel: string;
  recentAccessedPage: PageResponse;
};

const ChapterProgressBar: FunctionComponent<ChapterProgressBarProps> = ({
  remainedWithLabel,
  recentAccessedPage,
}) => {
  const navigate = useNavigate();

  const {
    activity: {
      value: { id: activityId, title: activityTitle },
    },
    chapter: {
      value: { id: chapterId },
    },
  } = recentAccessedPage;

  const { progress: percentage, isLoading } = useChapter(chapterId);

  if (!isLoading) {
    const handleGoToActivityPage = (event: MouseEvent) => {
      event.stopPropagation();
      navigate(`/activities/${activityId}`);
    };
    return (
      <ChapterProgressWrapper data-testid="chapter-progress-bar">
        <div>
          <RemainedButton onClick={handleGoToActivityPage}>
            <ChapterProgressIcon icon="arrow-right" />
            <div>
              <ChapterProgressText>{remainedWithLabel}</ChapterProgressText>
              <BodyLargeEmphasis>{activityTitle}</BodyLargeEmphasis>
            </div>
          </RemainedButton>
          {percentage !== undefined && (
            <ChapterProgress>
              <Progress percentage={percentage} />
            </ChapterProgress>
          )}
        </div>
      </ChapterProgressWrapper>
    );
  }
  return <CenteredSpinner />;
};

export default ChapterProgressBar;
