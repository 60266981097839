import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Outlet, useParams } from "react-router-dom";

import { useHeaderUpdater } from "@components/HeaderContextProvider";
import { usePlayerState } from "@components/PlayerLayout";
import { ConfirmPageLeaveOptions } from "@domain/types";
import { assertIsDefined } from "@domain/utils";
import useDynamicActivity from "@hooks/useDynamicActivity";

import CenteredSpinner from "@topgun/shared-front-end/src/components/CenteredSpinner";

type TrainerPlayerProps = {
  configurationId: string;
  dynamicActivityId: string;
};

const TrainerLayout = () => {
  const { configurationId, dynamicActivityId } = useParams<TrainerPlayerProps>();
  assertIsDefined(configurationId, "Unknown configuration ID");
  assertIsDefined(dynamicActivityId, "Unknown dynamic-activity ID");

  const { dynamicActivity, isLoading } = useDynamicActivity(dynamicActivityId);
  const { setNavigateBack, setBreadcrumbs, setLeaveConfirmationOptions } = useHeaderUpdater();
  const { t } = useTranslation();
  const { type: trainerType, title: trainerTitle } = dynamicActivity ?? {};
  const { progress, setProgress } = usePlayerState();

  useEffect(() => {
    setNavigateBack({
      to: `/trainers/${configurationId}`,
      icon: "cross",
    });
  }, [setNavigateBack, configurationId]);

  useEffect(() => {
    let confirmationOptions: ConfirmPageLeaveOptions = {
      title: t("modal.exitActivityTitle"),
      description: t("modal.exitActivityDescription"),
      confirmButton: t("modal.exitActivityConfirm"),
      cancelButton: t("modal.exitActivityCancel"),
    };
    if (trainerType === "CHAPTER_TEST") {
      confirmationOptions = {
        ...confirmationOptions,
        title: t("modal.exitChapterTestTitle"),
        confirmButton: t("modal.exitChapterTestConfirm"),
      };
    } else if (trainerType === "PRACTICE_EXAM") {
      confirmationOptions = {
        ...confirmationOptions,
        title: t("modal.exitPractiseExamTitle"),
        confirmButton: t("modal.exitPractiseExamConfirm"),
      };
    }
    setLeaveConfirmationOptions(confirmationOptions);
  }, [t, setLeaveConfirmationOptions, trainerType]);

  useEffect(() => {
    if (trainerTitle) {
      setBreadcrumbs([
        {
          label: trainerTitle,
          to: "/trainers",
        },
      ]);
    }
  }, [trainerTitle, setBreadcrumbs]);

  if (isLoading) {
    return <CenteredSpinner />;
  }

  return <Outlet context={{ dynamicActivity, progress, setProgress }} />;
};

export default TrainerLayout;
