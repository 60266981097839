const {
  REACT_APP_AUTH_AUTHORITY,
  REACT_APP_AUTH_CLIENT_ID,
  REACT_APP_AUTH_REDIRECT_URI,
  REACT_APP_AUTHORIZATION_ENDPOINT,
  REACT_APP_TOKEN_ENDPOINT,
  REACT_APP_SIGN_OUT_URL,
  REACT_APP_AUTH_LOG_OUT_URI,
} = process.env;

export const USER_KEY = "anwb-user";

export const AUTH_AUTHORITY = REACT_APP_AUTH_AUTHORITY || "";
export const AUTH_CLIENT_ID = REACT_APP_AUTH_CLIENT_ID || "";
export const AUTH_REDIRECT_URI = REACT_APP_AUTH_REDIRECT_URI || "";
export const AUTHORIZATION_ENDPOINT = REACT_APP_AUTHORIZATION_ENDPOINT;
export const TOKEN_ENDPOINT = REACT_APP_TOKEN_ENDPOINT;
export const SIGN_OUT_URL = REACT_APP_SIGN_OUT_URL;
export const AUTH_LOG_OUT_URI = REACT_APP_AUTH_LOG_OUT_URI ?? "https://www.anwbtheorie.nl";

export const OAUTH_KEY = `oidc.user:${AUTH_AUTHORITY}:${AUTH_CLIENT_ID}`;
