import { FunctionComponent, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { StatusDoingIcon, StatusDoneIcon } from "@components/Application/styles";
import CurrentChapterCard from "@components/CurrentChapterCard";
import { useHeaderUpdater } from "@components/HeaderContextProvider";
import NextChapterCard from "@components/NextChapterCard";
import { Status } from "@domain/types";
import useChapters from "@hooks/useChapters";
import useRecentAccessedPage from "@hooks/useRecentAccessedPage";

import Grid from "@anwb/poncho/components/grid";
import { useApplicationSize } from "@anwb/poncho/providers/providers-application";

import CenteredSpinner from "@topgun/shared-front-end/src/components/CenteredSpinner";
import { ContentType } from "@topgun/shared-front-end/src/types/frontend";

import arrow from "./chapter-overview-arrow.png";
import {
  ChapterCardGrid,
  ChapterCardWrapper,
  ChapterDetailsWrapper,
  ChapterOverviewCtaArrow,
  ChapterOverviewCtaLabel,
  ChapterOverviewCtaWrapper,
  ChapterOverviewGrid,
  ChapterOverviewProceedWith,
  ChapterOverviewProceedWithTitle,
  ChapterOverviewProceedWithWrapper,
  ChapterOverviewWrapper,
  ChapterPanel,
  ChapterPanelContent,
  ChapterProgressDetails,
  ChapterProgressPartsAmount,
  ChapterProgressStatus,
  ChapterSummary,
  ChapterTitle,
  HorizontalDivider,
} from "./styles";

const ChapterOverview: FunctionComponent = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { clearNavigateBack, setNavigateBack } = useHeaderUpdater();

  const applicationSize = useApplicationSize();
  const { chapters } = useChapters();
  const { data: recentAccessedPage } = useRecentAccessedPage();

  useEffect(() => {
    if (applicationSize === "small") {
      clearNavigateBack();
    } else {
      setNavigateBack({
        to: "/dashboard",
      });
    }
  }, [applicationSize, clearNavigateBack, setNavigateBack]);

  useEffect(
    () => () => {
      clearNavigateBack();
    },
    [clearNavigateBack],
  );

  const handleGoToChapter = (chapterId: string) => {
    navigate(`/chapters/${chapterId}`);
  };

  if (chapters?.length) {
    return (
      <ChapterOverviewWrapper data-testid="chapterOverview">
        {recentAccessedPage && (
          <ChapterOverviewGrid columns={2}>
            <Grid.Item>
              <ChapterOverviewProceedWithWrapper>
                <ChapterOverviewProceedWithTitle>
                  {t("chapterOverview.progressProceedWithTitle")}
                </ChapterOverviewProceedWithTitle>
                <ChapterOverviewProceedWith>
                  {t("chapterOverview.progressProceedWith")}
                </ChapterOverviewProceedWith>
              </ChapterOverviewProceedWithWrapper>
            </Grid.Item>
            <Grid.Item>
              <CurrentChapterCard recentAccessedPage={recentAccessedPage} />
            </Grid.Item>
          </ChapterOverviewGrid>
        )}

        <ChapterOverviewGrid columns={2}>
          <Grid.Item>
            <ChapterOverviewCtaWrapper>
              <ChapterOverviewCtaLabel>
                {t(
                  applicationSize === "small"
                    ? "chapterOverview.remainInPageCtaMobile"
                    : "chapterOverview.remainInPageCta",
                )}
              </ChapterOverviewCtaLabel>
              <ChapterOverviewCtaArrow
                src={arrow as string}
                alt={`${t("chapterOverview.remainInPageCtaArrowAlt")}`}
              />
            </ChapterOverviewCtaWrapper>
          </Grid.Item>
          <Grid.Item>
            <HorizontalDivider />

            <ChapterCardGrid columns={1}>
              {chapters.map((chapter) => {
                const activityCount = [
                  ...chapter.filterBreadthFirst((item) => item?.type === ContentType.ACTIVITY),
                ].length;
                const { id, title, status, summary } = chapter.value;
                return (
                  <ChapterPanel key={id} onClick={() => handleGoToChapter(id)}>
                    <ChapterPanelContent>
                      <ChapterCardWrapper>
                        <NextChapterCard nextChapter={chapter} showChapterTitle={false} />
                      </ChapterCardWrapper>
                      <ChapterDetailsWrapper>
                        <ChapterTitle>{title}</ChapterTitle>
                        <ChapterSummary>{summary}</ChapterSummary>
                        <ChapterProgressDetails>
                          <ChapterProgressPartsAmount>
                            {activityCount}
                            &nbsp;
                            {t("chapterOverview.partsLabel", {
                              count: activityCount,
                            })}
                          </ChapterProgressPartsAmount>
                          {status && [Status.DOING, Status.DONE].includes(status) && (
                            <>
                              {status === Status.DONE && <StatusDoneIcon />}
                              {status === Status.DOING && <StatusDoingIcon />}
                              <ChapterProgressStatus>{t(`status.${status}`)}</ChapterProgressStatus>
                            </>
                          )}
                        </ChapterProgressDetails>
                      </ChapterDetailsWrapper>
                    </ChapterPanelContent>
                  </ChapterPanel>
                );
              })}
            </ChapterCardGrid>
          </Grid.Item>
        </ChapterOverviewGrid>
      </ChapterOverviewWrapper>
    );
  }
  return <CenteredSpinner size="large" />;
};

export default ChapterOverview;
