import { useEffect, useMemo, useState } from "react";

import useLearningApi from "@hooks/useLearningApi";
import { useAuth } from "oidc-react";

import { AnwbServerSideEventDispatcher } from "./AnwbServerSideEventDispatcher";

export interface LearningObjectContext {
  learningObjectId: string;
  context: string;
  contextType: string;
}

export const useEndUserServerSideEventDispatcher = (
  learningObjectContext: LearningObjectContext,
) => {
  const [token, setToken] = useState<string | null>(null);
  const [userId, setUserId] = useState<string | null>(null);
  const auth = useAuth();
  const { api: learningApi } = useLearningApi();

  useEffect(() => {
    if (!auth.isLoading && auth.userData?.id_token && auth.userData?.profile.sub) {
      setToken(auth.userData.id_token);
      setUserId(auth.userData.profile.sub);
    }
  }, [auth.isLoading, auth.userData?.id_token, auth.userData?.profile.sub]);

  return useMemo(() => {
    if (learningApi && token && userId) {
      return new AnwbServerSideEventDispatcher(learningApi, {
        ...learningObjectContext,
        userId: userId,
        issuer: auth.userData!.profile.iss,
      });
    }
    return undefined;
  }, [token, userId, learningObjectContext]);
};
