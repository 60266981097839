import { ContentItem, Status } from "@domain/types";
import { Node } from "@utils/tree/Node";

import { ContentType } from "@topgun/shared-front-end/src/types/frontend";

export class ContentStatusHelper {
  /**
   * Recursively traverses the given content tree, setting the status of each level.
   * If a PAGE level content's id is found in the finishedContent array, it's status is set to DONE, otherwise OPEN.
   * For non-PAGE level content, the status is determined by the status of its children, as defined by the getStatusBasedOnChildren function.
   *
   * @note This function will mutate the content object by changing its status.
   *
   * @param content - The content tree on which statuses are to be set.
   * @param finishedContent - An array of content IDs that are already marked as finished.
   *
   * @returns {void}
   */
  /* eslint-disable no-param-reassign */
  public static setStatusByFinishedContent(
    content: Node<ContentItem | null>,
    finishedContent: string[],
  ): void {
    for (const node of content.depthFirstPostOrderTraversal()) {
      const { value } = node;
      if (value == null) {
        continue;
      }
      const { type, learningObjectId } = value;

      if (type === ContentType.PAGE) {
        if (learningObjectId) {
          value.status = finishedContent.includes(learningObjectId) ? Status.DONE : Status.OPEN;
        } else {
          value.status = undefined;
        }
      } else {
        value.status = ContentStatusHelper.getStatusBasedOnChildren(node);
      }
    }
  }

  public static getActivityProgressPercentage(content: Node<ContentItem | null>): number {
    const activities = [
      ...content.filterBreadthFirst((item) => item?.type === ContentType.ACTIVITY),
    ].filter((node): node is Node<ContentItem> => !!node.value);

    if (activities.length === 0) {
      return 0;
    }

    return Math.floor(
      (activities.reduce(
        (acc, current) => acc + (current.value.status === Status.DONE ? 1 : 0),
        0,
      ) /
        activities.length) *
        100,
    );
  }

  private static isInProgress(allDone: boolean, allOpen: boolean) {
    return !allDone && !allOpen;
  }

  /**
   * Determine the status of the content based on the status of its children.
   *
   * @param content - The content to check.
   * @returns {Status} The overall status of the content.
   */
  private static getStatusBasedOnChildren(content: Node<ContentItem | null>): Status {
    let allOpen = true;
    let allDone = true;

    // eslint-disable-next-line no-restricted-syntax
    for (const child of content.children) {
      const { status } = child.value ?? {};
      switch (status) {
        case undefined:
          continue;
        case Status.OPEN:
          allDone = false;
          break;
        case Status.DONE:
          allOpen = false;
          break;
        default:
          allDone = false;
          allOpen = false;
      }

      if (this.isInProgress(allDone, allOpen)) {
        break;
      }
    }

    if (allOpen) {
      return Status.OPEN;
    }
    if (allDone) {
      return Status.DONE;
    }
    return Status.DOING;
  }
}
