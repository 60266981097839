import { FunctionComponent } from "react";

import Notification from "@components/Notification";
import TrainerCard from "@components/TrainerCard";
import { TrainerGridWrapper } from "@components/TrainerGrid/styles";
import { IConfiguration, MaterialType } from "@domain/types";
import { getNotificationStructure } from "@utils/helpers";

import { GridProps } from "@anwb/poncho/components/grid";

import CenteredSpinner from "@topgun/shared-front-end/src/components/CenteredSpinner";

type Props = {
  columns: GridProps["columns"];
  count: number;
  isLoading: boolean;
  error: unknown;
  trainers: IConfiguration[] | undefined;
};

const TrainerGrid: FunctionComponent<Props> = ({ columns, count, isLoading, error, trainers }) => {
  if (isLoading) {
    return <CenteredSpinner />;
  }

  if (error) {
    const errorNotification = getNotificationStructure(MaterialType.TRAINERS);
    return (
      <Notification variant={errorNotification.variant} title={errorNotification.title}>
        {errorNotification.children}
      </Notification>
    );
  }

  return (
    <TrainerGridWrapper columns={columns} data-test="trainer-grid">
      {trainers
        ?.slice(0, count)
        .map((item) => <TrainerCard key={`trainer-card-${item.id}`} trainer={item} />)}
    </TrainerGridWrapper>
  );
};

export default TrainerGrid;
