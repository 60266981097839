import { ButtonPrimaryDark, ButtonSecondaryDark, ButtonTertiaryDark } from "@components/buttons";
import { animated } from "@react-spring/web";
import styled, { css } from "styled-components";

import GridItem from "@anwb/poncho/components/grid/GridItem";
import Icon from "@anwb/poncho/components/icon";
import { pxToRem } from "@anwb/poncho/design-tokens/style-utilities";
import { DefaultThemeProps } from "@anwb/poncho/design-tokens/theme";

import { customVariables } from "@topgun/shared-front-end/src/theme/custom-variables";
import {
  HeadingCompressedBoldL,
  HeadingCompressedBoldM,
} from "@topgun/shared-front-end/src/typography";
import { HeadingCompressedBoldSStyles } from "@topgun/shared-front-end/src/typography/shared/Typography.styled";

export const OnBoardingModal = styled.div`
  position: fixed;
  width: 100dvw;
  height: 100dvh;
  top: 0;
  background-color: ${customVariables.colors.modalOverlay};
  z-index: 1000;

  @media (min-width: ${({ theme }: DefaultThemeProps) => pxToRem(theme.viewportBreakpoint.sm)}) {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`;

export const OnBoardingNavigation = styled.div`
  position: fixed;
  width: 100dvw;
  margin: auto;
  padding: 1rem 1.5rem;
  display: flex;
  pointer-events: none;

  ${ButtonPrimaryDark}, ${ButtonSecondaryDark}, ${ButtonTertiaryDark} {
    width: 100%;
  }

  > div {
    gap: 10px;
    display: flex;
    flex-direction: row;
    pointer-events: auto;
  }

  @media (max-width: ${({ theme }: DefaultThemeProps) => pxToRem(theme.viewportBreakpoint.sm)}) {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    ${ButtonTertiaryDark} {
      background-color: ${({ theme }: DefaultThemeProps) => theme.colors.blanc.bgModal};
    }
  }
  @media (min-width: ${({ theme }: DefaultThemeProps) => pxToRem(theme.viewportBreakpoint.sm)}) {
    bottom: 0;
    gap: 10px;
    align-items: center;

    > div:nth-child(1) {
      flex: 33.33% 1 0;
    }
    > div:nth-child(2) {
      flex: 66.66% 1 0;
    }
  }
`;

export const BackIconButton = styled(Icon)`
  min-height: 0;
  cursor: pointer;
  flex: 20px 0 1;
`;
export const OnBoardingDotsContainer = styled.div`
  height: 24px;
  display: flex;
  align-items: center;
  gap: 10px;
  flex: 1;
`;
export const OnBoardingDot = styled.div`
  height: 10px;
  background-color: ${customVariables.colors.navigationDots};
  border-radius: 10px;
  cursor: pointer;
  transition: 0.15s ease-in-out;
  flex: 1;

  &:hover,
  &.active {
    background-color: ${customVariables.colors.primaryBlue};
  }
`;

export const OnBoardingVideoSlide = styled.div`
  width: 100dvw;
  height: 100dvh;
`;
export const OnBoardingVideoContainer = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100dvw;
  height: 100dvh;
  overflow-y: hidden;
  display: flex;
`;
export const OnBoardingVideo = styled.video`
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const OnBoardingContentSlideStyles = ({ theme }: DefaultThemeProps) => css`
  background-color: ${theme.colors.blanc.bgModal};
  width: 80dvw;
  padding: 2rem 1.5rem;
  border-radius: 1.6rem;
  display: flex;
  flex-direction: column;

  @media (min-width: ${pxToRem(theme.viewportBreakpoint.sm)}) and (max-width: ${pxToRem(
      theme.viewportBreakpoint.md,
    )}) {
    width: 95dvw;
  }

  @media (max-width: ${pxToRem(theme.viewportBreakpoint.sm)}) {
    width: 100dvw;
    min-height: 100dvh;
    padding: 3.5rem 1.5rem 6rem;
  }
`;

export const OnBoardingContentSlide = styled.div`
  ${OnBoardingContentSlideStyles}
`;
export const OnBoardingVideoTitle = styled(HeadingCompressedBoldL)`
  position: relative;
  max-width: 30rem;
  padding: 2rem;
  font-size: 9rem;
  line-height: 7.5rem;
  text-transform: uppercase;
  color: ${customVariables.colors.primarySun};
  z-index: 1;

  @media (max-width: ${({ theme }: DefaultThemeProps) => pxToRem(theme.viewportBreakpoint.sm)}) {
    font-size: 6rem;
    line-height: 5rem;
    padding: 3.5rem 1.5rem 5rem;
    margin-top: auto;
  }
`;

export const OnBoardingGridItem = styled(GridItem)`
  display: flex;
  align-items: center;
`;

export const OnBoardingContentImage = styled.img`
  width: auto;
  max-width: 100%;
  max-height: 60dvh;
`;
export const AnimatedDiv = styled(animated.div)`
  position: absolute;

  @media (max-width: ${({ theme }: DefaultThemeProps) => pxToRem(theme.viewportBreakpoint.sm)}) {
    overflow-y: scroll;
    height: 100%;
  }
`;

const OnBoardingContentTitleStyles = () => css<{ isLastSlide: boolean }>`
  color: ${(props) => (props.isLastSlide ? customVariables.colors.primaryBlue : "")};
  margin-bottom: 1rem;
`;

export const OnBoardingContentTitle = styled(HeadingCompressedBoldM)`
  ${OnBoardingContentTitleStyles}
`;

export const OnBoardingContent = styled.div`
  h4 {
    ${HeadingCompressedBoldSStyles};
    margin-top: 1.5rem;
  }
`;

export const OnBoardingList = styled.ul`
  padding-left: 0;
  list-style-type: none;

  li {
    display: flex;
    align-items: start;

    span {
      color: ${customVariables.colors.uiSuccess};
      flex: 1.2rem 0 0;
    }

    > div {
      padding-left: 0.75rem;
    }
  }
`;
