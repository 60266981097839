import { FunctionComponent, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { ErrorDetails } from "@components/Activity/styles/activity.styled";
import Notification from "@components/Notification";
import Player from "@components/Player";
import { usePlayerState } from "@components/PlayerLayout";
import { useTrainerState } from "@components/Trainer/TrainerLayout/hooks";
import { MaterialType } from "@domain/types";
import { assertIsDefined } from "@domain/utils";
import usePrefetchPlayLearningObject from "@hooks/usePrefetchPlayLearningObject";
import { getNotificationStructure } from "@utils/helpers";

import { LearningObjectContext } from "../../../event/dispatcher/ServerSideEventDispatcherContext";

type TrainerPlayerProps = {
  configurationId: string;
  dynamicActivityId: string;
  pageId: string;
};

const TrainerPage: FunctionComponent = () => {
  const { configurationId, dynamicActivityId, pageId } = useParams<TrainerPlayerProps>();
  assertIsDefined(configurationId, "Unknown configuration ID");
  assertIsDefined(dynamicActivityId, "Unknown dynamic-activity ID");
  assertIsDefined(pageId, "Unknown dynamic-activity page ID");

  const { setProgress } = usePlayerState();
  const { dynamicActivity } = useTrainerState();
  const navigate = useNavigate();
  const { pages } = dynamicActivity ?? {};

  const foundPage = pages ? pages.find((page) => page.id === pageId) : undefined;
  const learningObjectId = foundPage?.learningObjectId;
  const pageIndex = pages?.findIndex((page) => page.id === pageId) || 0;
  const segmentId = pages?.find((page) => page.id === pageId)?.segment_id;

  const nextPage = pages?.[pageIndex + 1];
  const nextLearningObjectId = nextPage?.learningObjectId;

  usePrefetchPlayLearningObject(nextLearningObjectId);

  useEffect(() => {
    if (pages && dynamicActivity?.segments) {
      const currentPageInSegment =
        pages
          .filter((page) => page.segment_id === segmentId)
          .findIndex((page) => page.id === pageId) + 1;
      const allPagesInSegment = pages.filter((page) => page.segment_id === segmentId).length;
      setProgress([currentPageInSegment, allPagesInSegment]);
    } else {
      setProgress(pages ? [pageIndex + 1, pages.length] : undefined);
    }
  }, [setProgress, pages, pageIndex, pageId, segmentId, dynamicActivity]);

  const goToNextPage = () => {
    if (!pages?.length) {
      return;
    }

    const isLastPage = pages && pageIndex === pages.length - 1;
    const isNextPageNewSegment = segmentId !== pages[pageIndex + 1]?.segment_id;
    if (isLastPage) {
      navigate(`/trainers/${configurationId}/${dynamicActivityId}/result`);
    } else if (isNextPageNewSegment) {
      const nextSegmentId = nextPage?.segment_id;
      navigate(`/trainers/${configurationId}/${dynamicActivityId}/status/${nextSegmentId}`);
    } else {
      const nextPageId = nextPage?.id;
      navigate(`/trainers/${configurationId}/${dynamicActivityId}/page/${nextPageId}`);
    }
  };

  const goToPreviousPage = () => {
    if (!pages?.length || pageIndex === 0) {
      return;
    }

    const prevPageIsNewSegment = pages?.[pageIndex - 1].segment_id !== segmentId;
    if (prevPageIsNewSegment) {
      const prevSegmentId = pages?.[pageIndex].segment_id;
      navigate(`/trainers/${configurationId}/${dynamicActivityId}/intro/${prevSegmentId}`);
    } else {
      const previousPageId = pages[pageIndex - 1].id;
      navigate(`/trainers/${configurationId}/${dynamicActivityId}/page/${previousPageId}`);
    }
  };

  if (learningObjectId) {
    const learningObjectContext: LearningObjectContext = {
      learningObjectId,
      context: dynamicActivityId,
      contextType: "DYNAMIC_ACTIVITY",
    };
    return (
      <Player
        pageIndex={pageIndex}
        onPreviousClick={goToPreviousPage}
        learningObjectContext={learningObjectContext}
        onNextClick={goToNextPage}
      />
    );
  }

  const error = getNotificationStructure(MaterialType.TRAINERS);
  return (
    <Notification $alignNotification variant={error.variant} title={error.title}>
      {error.children}
      <ErrorDetails>NO_SOURCES: {dynamicActivityId}</ErrorDetails>
    </Notification>
  );
};

export default TrainerPage;
