import { ContainerSize } from "@components/CallToActionBlock/CallToActionBlock";
import { ButtonPrimaryDark } from "@components/buttons";
import checkmark from "shared/cta/checkmark.png";
import styled from "styled-components";

import { pxToRem } from "@anwb/poncho/design-tokens/style-utilities";
import { DefaultThemeProps } from "@anwb/poncho/design-tokens/theme";

import { customVariables } from "@topgun/shared-front-end/src/theme/custom-variables";
import { HeadingCompressedBoldM } from "@topgun/shared-front-end/src/typography";
import bodyL from "@topgun/shared-front-end/src/typography/BodyL";

export const CallToActionGradientBorder = styled.a`
  position: relative;
  margin-top: 2rem;
  padding: 1px;
  color: ${customVariables.colors.ctaContentColor};
  text-decoration: none;
  border-radius: 0.8rem;
  display: block;
  background: linear-gradient(
    90deg,
    ${customVariables.colors.ctaBlockGradientPurple},
    ${customVariables.colors.ctaBlockGradientBlue}
  );
`;

export const CallToActionCard = styled.div<{ backgroundImg: string }>`
  position: relative;
  padding: 2rem 2.25rem;
  transition: ease-in-out 0.3s;
  border-radius: 0.75rem;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url(${({ backgroundImg }) => backgroundImg});
  background-position: center;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  overflow: hidden;
  text-align: left;

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: ${customVariables.colors.primaryBackground};
    opacity: 0.5;
  }

  > * {
    position: relative;
    z-index: 1;
  }

  @media (max-width: ${(props: DefaultThemeProps) => pxToRem(props.theme.viewportBreakpoint.md)}) {
    padding: 1.5rem 1.25rem;
  }
`;

export const CallToActionTitle = styled(HeadingCompressedBoldM)`
  > span {
    color: ${customVariables.colors.primarySun};
  }
`;

export const CallToActionContent = styled(bodyL)`
  margin-top: 1.5rem;
`;

export const CallToActionList = styled.ul`
  padding-left: 0;
  list-style-type: none;
  &&& li {
    padding-left: 2.5rem;
    background-image: url(${checkmark});
    background-repeat: no-repeat;
  }
`;

export const CallToActionButton = styled(ButtonPrimaryDark).attrs({
  tagName: "div",
})<{ containerSize: ContainerSize }>`
  margin-top: 1.5rem;
  margin-left: auto;
  width: ${({ containerSize }) => (containerSize === "small" ? "100%" : "auto")};

  @media (max-width: ${(props: DefaultThemeProps) => pxToRem(props.theme.viewportBreakpoint.md)}) {
    width: 100%;
  }
`;
