import { useState } from "react";
import { Outlet } from "react-router-dom";

import { onBeforeSignIn } from "@components/AuthProviderWithConfig";
import { useAuth } from "oidc-react";

import CenteredSpinner from "@topgun/shared-front-end/src/components/CenteredSpinner";

function ProtectedLayout() {
  const { userManager } = useAuth();
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  userManager
    .getUser()
    .then((loggedInUser) => {
      if (!loggedInUser) {
        const state = onBeforeSignIn();
        userManager
          .signinRedirect({ state })
          .then(() => {
            console.debug("Redirecting to sign in page");
          })
          .catch((reason) => {
            throw reason;
          });
      } else {
        setIsLoggedIn(true);
      }
    })
    .catch((reason) => {
      throw reason;
    });
  return (
    <div data-testid="protected-layout">
      {isLoggedIn ? <Outlet /> : <CenteredSpinner size="large" />}
    </div>
  );
}

export default ProtectedLayout;
