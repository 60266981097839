import { SecondaryNavigationButtonIcon } from "@components/buttons";
import styled, { css } from "styled-components";

import { pxToRem } from "@anwb/poncho/design-tokens/style-utilities";
import { Theme } from "@anwb/poncho/design-tokens/theme";

import BodyL from "@topgun/shared-front-end/src/typography/BodyL";

type DefaultThemeProps = {
  theme: Theme;
};

export const ChapterProgressWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const remainedButtonStyles = ({ theme }: DefaultThemeProps) => css`
  cursor: pointer;
  display: inline-flex;
  flex-direction: row;
  width: fit-content;

  :hover {
    p {
      color: ${theme.colors.light.borderReceipt};
    }
    button {
      background-color: ${theme.colors.light.borderReceipt};
    }
    span {
      position: relative;
      transform: translateX(0.2rem);
      transition: transform 0.2s ease 0s;
    }
  }
`;

export const RemainedButton = styled.a`
  ${remainedButtonStyles};
`;

const chapterProgressIconStyles = ({ theme }: DefaultThemeProps) => css`
  margin: auto 1rem auto 0.5rem;

  @media (max-width: ${pxToRem(theme.viewportBreakpoint.sm)}) {
    display: none;
  }
`;

export const ChapterProgressIcon = styled(SecondaryNavigationButtonIcon)`
  ${chapterProgressIconStyles};
`;

export const ChapterProgressText = styled(BodyL)`
  margin-bottom: 0;
  margin-top: 0;
`;

export const ChapterProgress = styled.div`
  margin-top: 0.75rem;

  // TODO: is still selector still in use?
  .PONCHO-progress__value {
    display: none !important;
  }
`;
