import { useEffect } from "react";
import { useParams } from "react-router-dom";

import useRegister, { RegisterStatus } from "@hooks/useRegister";

import CenteredSpinner from "@topgun/shared-front-end/src/components/CenteredSpinner";

type RegisterProps = {
  licenseId: string;
  joinedVia: string;
};

function Register() {
  const { licenseId, joinedVia } = useParams<RegisterProps>();

  if (!licenseId || !joinedVia) {
    throw new Error("Invalid parameter(s) licenseId and/or joinedVia");
  }
  const registerStatus = useRegister(licenseId, joinedVia);

  useEffect(() => {
    switch (registerStatus) {
      case RegisterStatus.LoggedIn:
        window.location.href = "/";
        break;
      case RegisterStatus.Forbidden:
        window.location.href = "/licence-error";
        break;
      default:
    }
  }, [registerStatus]);

  return <CenteredSpinner size="large" />;
}

export default Register;
