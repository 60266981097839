import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";

import { StatusDoingIcon, StatusDoneIcon } from "@components/Application/styles";
import ChapterNumberTag from "@components/ChapterNumberTag";
import ChapterProgressBar from "@components/ChapterProgressBar";
import { useHeaderUpdater } from "@components/HeaderContextProvider";
import TrainerCard from "@components/TrainerCard";
import { Status } from "@domain/types";
import { assertIsDefined } from "@domain/utils";
import useChapter from "@hooks/useChapter";
import useDynamicActivityConfigurations from "@hooks/useDynamicActivityConfigurations";
import useRecentAccessedPage from "@hooks/useRecentAccessedPage";

import Grid from "@anwb/poncho/components/grid";
import { useApplicationSize } from "@anwb/poncho/providers/providers-application";

import CenteredSpinner from "@topgun/shared-front-end/src/components/CenteredSpinner";
import { MarkdownSanitized } from "@topgun/shared-front-end/src/components/MarkdownSanitized/MarkdownSanititzed";
import { BodyL } from "@topgun/shared-front-end/src/typography";

import {
  ActivityOverlayWrapper,
  ActivityStatusWrapper,
  ActivityStructureWrapper,
  ActivityTitle,
  ActivityWrapper,
  ChapterBackgroundWrapper,
  ChapterContentWrapper,
  ChapterGrid,
  ChapterNumberTagWrapper,
  ChapterProgressBarWrapper,
  ChapterTitle,
  ChapterTitleWrapper,
  SectionTitle,
  SectionWrapper,
} from "./styles";

type ChapterUrlProps = {
  chapterId: string;
};
const TOP_SCROLL_POSITION = 0;

export default function Chapter() {
  const { chapterId } = useParams<ChapterUrlProps>();
  assertIsDefined(chapterId, "Unknown chapter ID");

  const { t } = useTranslation();
  const navigate = useNavigate();
  const applicationSize = useApplicationSize();
  const { setShowHeaderMenu, clearNavigateBack, setNavigateBack } = useHeaderUpdater();

  const { chapter, sections, isLoading: isLoadingChapter } = useChapter(chapterId);
  const { data: recentAccessedPage } = useRecentAccessedPage();

  const { error: errorLoadingChapterTest, dynamicActivityConfigurations: [chapterTest] = [] } =
    useDynamicActivityConfigurations(["CHAPTER_TEST"], chapterId);

  useEffect(() => {
    setNavigateBack({
      to: "/chapters",
    });
    window.scrollTo({ top: TOP_SCROLL_POSITION });
  }, [setNavigateBack]);

  useEffect(() => {
    setShowHeaderMenu(applicationSize !== "small");
  }, [setShowHeaderMenu, applicationSize]);

  useEffect(
    () => () => {
      clearNavigateBack();
      setShowHeaderMenu(true);
    },
    [clearNavigateBack, setShowHeaderMenu],
  );

  const handleGoToActivity = (activityId: string) => {
    navigate(`/activities/${activityId}`);
  };

  if (chapterId && !chapter && !isLoadingChapter) {
    const notFoundError = new Error(`Chapter '${chapterId}' not found`);
    notFoundError.name = "notFound";
    throw notFoundError;
  }

  if (errorLoadingChapterTest) {
    const newError = chapterId
      ? new Error(`Exam for'${chapterId}' not found`)
      : new Error(`Exam not found`);
    newError.name = "notFound";
    throw newError;
  }
  if (recentAccessedPage && chapter) {
    const { sequenceLabel, title, image, summary } = chapter.value;

    return (
      <ChapterBackgroundWrapper image={image} data-testid="chapter">
        <ChapterContentWrapper>
          <ChapterGrid columns={{ sm: 1, md: 1, lg: 2 }}>
            <Grid.Item>
              <ChapterTitleWrapper>
                {sequenceLabel && (
                  <ChapterNumberTagWrapper>
                    <ChapterNumberTag chapterNumber={sequenceLabel} />
                  </ChapterNumberTagWrapper>
                )}

                <ChapterTitle tagName="h1">{title}</ChapterTitle>
              </ChapterTitleWrapper>
              <MarkdownSanitized markdown={summary ?? ""} container={BodyL} />
            </Grid.Item>
            <Grid.Item>
              <ChapterProgressBarWrapper>
                <ChapterProgressBar
                  recentAccessedPage={recentAccessedPage}
                  remainedWithLabel={t("chapterDetails.progressProceedWithCta")}
                />
              </ChapterProgressBarWrapper>
              {sections &&
                sections.map((section) => (
                  <SectionWrapper key={section.value.id}>
                    <SectionTitle dataTest="section-title">{section.value.title}</SectionTitle>

                    {section.children.map((activity) => (
                      <ActivityWrapper key={activity.value.id}>
                        <ActivityStructureWrapper>
                          <ActivityOverlayWrapper
                            onClick={() => handleGoToActivity(activity.value.id)}
                          >
                            <ActivityTitle>
                              {activity.value.sequenceLabel} {activity.value.title}
                            </ActivityTitle>
                            {activity.value.status && (
                              <ActivityStatusWrapper>
                                {activity.value.status === Status.DONE && <StatusDoneIcon />}
                                {activity.value.status === Status.DOING && <StatusDoingIcon />}
                              </ActivityStatusWrapper>
                            )}
                          </ActivityOverlayWrapper>
                        </ActivityStructureWrapper>
                      </ActivityWrapper>
                    ))}
                  </SectionWrapper>
                ))}
              {chapterTest && (
                <SectionWrapper>
                  <SectionTitle data-testid="chapter-test-title">{t("chapterTest")}</SectionTitle>
                  <TrainerCard data-testid="chapter-test-card" trainer={chapterTest} />
                </SectionWrapper>
              )}
            </Grid.Item>
          </ChapterGrid>
        </ChapterContentWrapper>
      </ChapterBackgroundWrapper>
    );
  }
  return <CenteredSpinner size="large" />;
}
