import { Client } from "@domain/generated/learning-record-openapi";
import { DateTime } from "luxon";

import { CompletedEvent } from "@topgun/shared-front-end/src/types/CompletedEvent";
import { ServerSideEventDispatcher } from "@topgun/shared-front-end/src/types/ServerSideEventDispatcher";

export class AnwbServerSideEventDispatcher implements ServerSideEventDispatcher {
  private start?: DateTime<true>;

  private end?: DateTime<true>;

  // eslint-disable-next-line no-useless-constructor
  constructor(
    private readonly learningApi: Client,
    private readonly baseData: {
      issuer: string;
      userId: string;
      learningObjectId: string;
      context: string;
      contextType: string;
    },
    // eslint-disable-next-line no-empty-function
  ) {}

  trackTime(): void {
    this.start = DateTime.now();
  }

  public async dispatch(event: CompletedEvent): Promise<void> {
    if (!this.start) {
      throw new Error("This dispatcher only allows time-tracked events");
    }

    this.end = DateTime.now();

    await this.learningApi.postLearningRecord(undefined, {
      actor: {
        objectType: "Agent",
        openid: `${this.baseData.issuer.replace(/\/$/, "")}/${this.baseData.userId}`,
      },
      verb: {
        id: "https://w3id.org/xapi/cmi5/verbs/completed",
      },
      object: {
        id: `https://leeromgeving.anwb.nl/learning-object/${this.baseData.learningObjectId}`,
      },
      result: {
        completion: true,
        success: event.score === event.maxScore,
        score: {
          scaled: event.score / event.maxScore,
          raw: event.score,
          max: event.maxScore,
        },
        duration: this.end.diff(this.start).toISO(),
      },
      context: {
        contextActivities: {
          parent: [
            {
              id: `https://leeromgeving.anwb.nl/module/${this.baseData.contextType}/${this.baseData.context}`,
              definition: {
                type: "http://adlnet.gov/expapi/activities/module",
              },
              objectType: "Activity",
            },
          ],
        },
        platform: "https://leeromgeving.anwb.nl",
      },
      timestamp: this.end.toISO(),
    });
  }
}
