import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";

import CallToActionBlock from "@components/CallToActionBlock";
import CheckOutTitleWithIllustrationDynamicActivity, {
  DynamicActivityType,
} from "@components/CheckOutTitleWithIllustrationDynamicActivity/CheckOutTitleWithIllustrationDynamicActivity";
import { useClient } from "@components/ClientProvider";
import { useHeaderUpdater } from "@components/HeaderContextProvider";
import Notification from "@components/Notification";
import ScoreCard from "@components/Trainer/TrainerResult/ScoreCard";
import {
  AmountCorrect,
  ButtonWrapper,
  GridContainer,
  PrimaryButton,
  Score,
  TertiaryButton,
} from "@components/Trainer/TrainerResult/styles";
import {
  CheckOutGridItem,
  ContentWrapper,
  ResultAndButtonWrapper,
  ResultsBackground,
} from "@components/Trainer/styles/Trainer.styled";
import { ArrowIcon } from "@components/buttons";
import { INotificationStructure, IResult, MaterialType } from "@domain/types";
import { assertIsDefined } from "@domain/utils";
import { useCourseWithCTA } from "@hooks/useCourseWithCTA";
import { captureException } from "@sentry/react";
import { getNotificationStructure } from "@utils/helpers";
import { useAuth } from "oidc-react";

import { useApplicationSize } from "@anwb/poncho/providers/providers-application";

import CenteredSpinner from "@topgun/shared-front-end/src/components/CenteredSpinner";
import { customVariables } from "@topgun/shared-front-end/src/theme/custom-variables";
import { HeadingCompressedBoldM } from "@topgun/shared-front-end/src/typography";

export const isSuccessfulResult = (total: number, correct: number) => {
  return Math.floor((100 * correct) / total) >= 80;
};

const TrainerResult = () => {
  const auth = useAuth();
  const { configurationId, dynamicActivityId } = useParams();
  assertIsDefined(configurationId, "Unknown configuration ID");
  assertIsDefined(dynamicActivityId, "Unknown dynamic-activity ID");

  const { dataClient } = useClient();
  const [result, setResult] = useState<IResult>();
  const [error, setError] = useState<INotificationStructure | null>(null);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const applicationSize = useApplicationSize();
  const { setShowHeaderMenu, setNavigateBack, enableLeaveConfirmation, disableLeaveConfirmation } =
    useHeaderUpdater();

  const isCourseWithCTA = useCourseWithCTA();
  useEffect(() => {
    setShowHeaderMenu(applicationSize !== "small");
  }, [setShowHeaderMenu, applicationSize]);

  useEffect(() => {
    setNavigateBack({
      to: `/trainers/${configurationId}`,
      icon: "cross",
    });
  }, [setNavigateBack, configurationId]);

  useEffect(() => {
    enableLeaveConfirmation({
      title: t("modal.exitResultsTitle"),
      description: t("modal.exitResultsDescription"),
    });
    return () => {
      disableLeaveConfirmation();
    };
  }, [t, enableLeaveConfirmation, disableLeaveConfirmation]);

  useEffect(() => {
    if (!auth.userData) {
      return () => {};
    }

    const controller = new AbortController();
    const { signal } = controller;

    dataClient
      .getDynamicActivityResult(dynamicActivityId, signal, auth.userData.access_token)
      .then((apiResult: IResult) => {
        if (!signal.aborted) {
          setResult(apiResult);
        }
      })
      .catch((err) => {
        if (!signal.aborted) {
          captureException(new Error(err as string));
          setError(getNotificationStructure(MaterialType.TRAINERS));
        }
      });

    return () => {
      controller.abort();
    };
  }, [dynamicActivityId, configurationId, dataClient, auth.userData]);

  const navigateToDashboard = () => {
    navigate("/");
  };

  const navigateToTrainer = () => {
    navigate(`/trainers/${configurationId}`);
  };

  if (error) {
    return (
      <Notification $alignNotification variant={error.variant} title={error.title}>
        {error.children}
      </Notification>
    );
  }

  if (!result) {
    return <CenteredSpinner size="large" />;
  }

  const hasSegments = result.segments.length > 0;
  const isSuccess = hasSegments
    ? result.segmentResults.every((segmentResult) => segmentResult.success)
    : isSuccessfulResult(result.amountTotal, result.amountSuccess);

  return (
    <ResultsBackground>
      <ContentWrapper>
        <GridContainer constrainedWidth columns={{ sm: 1, md: 1, lg: 2 }}>
          <CheckOutGridItem>
            {hasSegments ? (
              <CheckOutTitleWithIllustrationDynamicActivity
                dynamicActivity={DynamicActivityType.PRACTICE_EXAM}
                isSuccess={isSuccess}
              />
            ) : (
              <CheckOutTitleWithIllustrationDynamicActivity
                dynamicActivity={DynamicActivityType.TRAINER}
                isSuccess={isSuccess}
              />
            )}
          </CheckOutGridItem>

          <CheckOutGridItem>
            <ResultAndButtonWrapper>
              {hasSegments ? (
                <ScoreCard segments={result.segments} segmentResults={result.segmentResults} />
              ) : (
                <>
                  <HeadingCompressedBoldM>
                    {t("trainerResult.correctQuestions1", { amount: result.amountTotal })}
                  </HeadingCompressedBoldM>
                  <AmountCorrect>
                    <Score data-testid="correct-amount" color={customVariables.colors.uiSuccess}>
                      {t("trainerResult.amountCorrect", { amountCorrect: result.amountSuccess })}
                    </Score>
                    &
                    <Score data-testid="wrong-amount" color={customVariables.colors.uiError}>
                      {t("trainerResult.amountIncorrect", {
                        amountIncorrect: (result.amountTotal || 0) - (result.amountSuccess || 0),
                      })}
                    </Score>
                  </AmountCorrect>
                </>
              )}

              <ButtonWrapper>
                {isSuccess ? (
                  <>
                    <TertiaryButton icon="refresh" onClick={navigateToTrainer}>
                      {t("trainerResult.tryAgain")}
                    </TertiaryButton>
                    <PrimaryButton icon={ArrowIcon.RIGHT} onClick={navigateToDashboard}>
                      {t("trainerResult.toDashboard")}
                    </PrimaryButton>
                  </>
                ) : (
                  <>
                    <PrimaryButton icon="refresh" onClick={navigateToTrainer}>
                      {t("trainerResult.tryAgain")}
                    </PrimaryButton>
                    <TertiaryButton icon={ArrowIcon.RIGHT} onClick={navigateToDashboard}>
                      {t("trainerResult.toDashboard")}
                    </TertiaryButton>
                  </>
                )}
              </ButtonWrapper>
            </ResultAndButtonWrapper>

            {isCourseWithCTA && <CallToActionBlock containerSize={"small"} />}
          </CheckOutGridItem>
        </GridContainer>
      </ContentWrapper>
    </ResultsBackground>
  );
};

export default TrainerResult;
