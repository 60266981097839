import { FunctionComponent } from "react";

import { InteractionLabel } from "@topgun/shared-front-end/src/typography/InteractionLabel";

import { ChapterNumberTagElement } from "./styles";

type ChapterNumberTagProps = {
  chapterNumber: string;
};

const ChapterNumberTag: FunctionComponent<ChapterNumberTagProps> = ({ chapterNumber }) => (
  <div data-testid="chapter-number-tag">
    <ChapterNumberTagElement>
      <InteractionLabel>H.{chapterNumber}</InteractionLabel>
    </ChapterNumberTagElement>
  </div>
);

export default ChapterNumberTag;
