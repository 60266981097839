import React, { FunctionComponent, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";

import { useTrainerState } from "@components/Trainer/TrainerLayout/hooks";
import {
  ContentText,
  SegmentIntroductionContainer,
  SegmentIntroductionWrapper,
  StyledButtonWrapper,
  TitleText,
} from "@components/Trainer/TrainerSegmentIntroduction/styles/TrainerSegmentIntroduction.styled";
import { ArrowIcon, ButtonSecondaryDark, NavigationButton } from "@components/buttons";
import { assertIsDefined } from "@domain/utils";

import { MarkdownSanitized } from "@topgun/shared-front-end/src/components/MarkdownSanitized/MarkdownSanititzed";

type TrainerUrlProps = {
  configurationId: string;
  dynamicActivityId: string;
  segmentId: string;
};

const TrainerSegmentIntroduction: FunctionComponent = () => {
  const { configurationId, dynamicActivityId, segmentId } = useParams<TrainerUrlProps>();
  assertIsDefined(configurationId, "Unknown configuration ID");
  assertIsDefined(dynamicActivityId, "Unknown dynamic-activity ID");
  assertIsDefined(segmentId, "Unknown segment ID");

  const { dynamicActivity } = useTrainerState();

  const navigate = useNavigate();
  const { t } = useTranslation();

  const segmentData = useMemo(
    () => dynamicActivity?.segments?.find((segment) => segment.id === segmentId),
    [dynamicActivity?.segments, segmentId],
  );

  const goToPrevPage = () => {
    navigate(`/trainers/${configurationId}/${dynamicActivityId}/status/${segmentId}`);
  };

  const goToNextPage = () => {
    const nextPage = dynamicActivity?.pages.find((page) => page.segment_id === segmentId);
    const nextPageId = nextPage?.id;
    navigate(`/trainers/${configurationId}/${dynamicActivityId}/page/${nextPageId}`);
  };

  return (
    <>
      <SegmentIntroductionWrapper>
        <SegmentIntroductionContainer>
          <TitleText tagName="h1" variant="content-title">
            {t("trainerSegments.introductionTitle")} <br />
            {segmentData?.title}
          </TitleText>

          <MarkdownSanitized markdown={segmentData?.content ?? ""} container={ContentText} />
        </SegmentIntroductionContainer>
      </SegmentIntroductionWrapper>
      <StyledButtonWrapper>
        <NavigationButton icon={ArrowIcon.LEFT} $variant="previous" onClick={goToPrevPage} />
        <ButtonSecondaryDark icon={ArrowIcon.RIGHT} onClick={goToNextPage}>
          {t("trainerSegments.introductionButtonStart", {
            segmentTitle: segmentData?.title.toLowerCase(),
          })}
        </ButtonSecondaryDark>
      </StyledButtonWrapper>
    </>
  );
};

export default TrainerSegmentIntroduction;
