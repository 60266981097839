import { FunctionComponent, ReactNode } from "react";

import {
  StyleRefreshWrapper,
  VideoWrapper,
} from "src/components/Player/PlayerWrapper/styles/playerWrapper.styled";

type Props = {
  contentType: string;
  children: ReactNode;
};

// TODO: these probably won't match any content type anymore after migration phase-out-h5p
const WIDE_CONTENT_TYPES = ["H5P.ANWBContentTypeVideo"];
const VIDEO_CONTENT_TYPES = ["H5P.ANWBContentTypeVideo"];

const PlayerWrapper: FunctionComponent<Props> = ({ contentType, children }) => {
  const className = `wrapper-${contentType.replace(".", "-")}`;

  // TODO: is VideoWrapper still in use?
  if (VIDEO_CONTENT_TYPES.includes(contentType)) {
    return <VideoWrapper className={className}>{children}</VideoWrapper>;
  }

  // TODO: is $wide still in use?
  return (
    <StyleRefreshWrapper $wide={WIDE_CONTENT_TYPES.includes(contentType)} className={className}>
      {children}
    </StyleRefreshWrapper>
  );
};

export default PlayerWrapper;
